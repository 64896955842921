import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Alert,
  Spinner,
} from "react-bootstrap";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { contactUs } from "../api/users";
import "../components/layout.scss";
import Layout from "../components/layout";

export const query = graphql`
  {
    contactUs : contentfulContactUs {
      title
      subHeading
      message {
        message
      }
      input1Title
      input2Title
      input3Title
      input1Placeholder
      input3Placeholder
      input2Placeholder
      submitButtonTitle
    }
  }
`

const StyledContainer = styled(Container)`
  padding-bottom: 40px;
`;
const StyledFormLabel = styled(Form.Label)`
  font-size: 18px;
  font-weight: 500;
  color: #271344;
  text-align: center;
`;

const ContactUsPage = ({data}) => {
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const { register, handleSubmit, formState, reset } = useForm({
    mode: "onChange",
  });

  const {
    contactUs:{
      title,
      subHeading,
      input1Title,
      input2Title,
      input3Title,
      input1Placeholder,
      input3Placeholder,
      input2Placeholder,
      submitButtonTitle,
      message:{
        message
      }
    }
  } = data;

  const onSubmit = async info => {
    setLoading(true);
    try {
      const newInfo = {
        ...info,
        email: info.email.toLowerCase(),
      };
      const respp = await contactUs(newInfo);
      setLoading(false);
      reset(respp);
    } catch (err) {
      setError("error in sending mail");
      setLoading(false);
    }
  };

  return (
    <Layout>
      <div id="content" className="site-content">
        <div className="content-inner">
          <div className="">
            <div className="row content-row">
              <div id="primary" className="content-area col-12">
                <main id="main" className="site-main">
                  <article
                    id="post-45"
                    className="post-45 page type-page status-publish hentry"
                  >
                    <div className="entry-content clearfix">
                      <div
                        data-elementor-type="wp-page"
                        data-elementor-id="45"
                        className="elementor elementor-45"
                        data-elementor-settings="[]"
                      >
                        <div className="elementor-section-wrap">
                          <section
                            className="elementor-section elementor-top-section elementor-element elementor-element-f6893e0 elementor-section-stretched elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                            data-id="f6893e0"
                            data-element_type="section"
                            data-settings='{"stretch_section":"section-stretched"}'
                          >
                            <div className="elementor-container elementor-column-gap-default ">
                              <div
                                className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-9f5f77a"
                                data-id="9f5f77a"
                                data-element_type="column"
                              >
                                <div className="elementor-widget-wrap elementor-element-populated">
                                  <div
                                    className="elementor-section elementor-inner-section elementor-element elementor-element-4522805 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                                    data-id="4522805"
                                    data-element_type="section"
                                  >
                                    <div className="elementor-container elementor-column-gap-default ">
                                      <div
                                        className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-367b21f"
                                        data-id="367b21f"
                                        data-element_type="column"
                                      >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                          <div
                                            className="elementor-element elementor-element-9265126 cms-element elementor-widget elementor-widget-cms_image_single"
                                            data-id="9265126"
                                            data-element_type="widget"
                                            data-widget_type="cms_image_single.default"
                                          >
                                            <div className="elementor-widget-container animation-top">
                                              <div className="cms-image-single cms-animation-bottom-to-top ">
                                                <div className="cms-img-box">
                                                  <StaticImage
                                                    placeholder="none"
                                                    width="107"
                                                    src="../images/image-home-3.png"
                                                    className="attachment-full size-full"
                                                    alt=""
                                                    loading="lazy"
                                                  />{" "}
                                                </div>
                                              </div>{" "}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-8869f2a"
                                        data-id="8869f2a"
                                        data-element_type="column"
                                      >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                          <div
                                            className="elementor-element elementor-element-8832270 elementor-widget elementor-widget-cms_heading"
                                            data-id="8832270"
                                            data-element_type="widget"
                                            data-widget_type="cms_heading.default"
                                          >
                                            <div className="elementor-widget-container">
                                              <div class="cms-heading-wrapper">
                                                <h3
                                                  class="custom-heading primary-color"
                                                  style={{
                                                    fontFamily:
                                                      "Schoolbell,Sans-serif",
                                                    fontSize: "17px",
                                                    fontWeight: 600,
                                                    letterSpacing: "3.5px",
                                                  }}
                                                >
                                                  <span>{subHeading}</span>
                                                </h3>
                                              </div>
                                              <div className="cms-heading-wrapper cms-heading-layout1 wow pulse">
                                                <h3 className="custom-heading">
                                                  <span>{title}</span>
                                                </h3>
                                                <div className="custom-heading-description">
                                                 {message}
                                                </div>
                                              </div>{" "}
                                            </div>

                                            <div>
                                              <StyledContainer>
                                                <Row className="justify-content-center">
                                                  <Col sm="8" md="9">
                                                    {error && (
                                                      <Alert variant="danger">
                                                        {error}
                                                      </Alert>
                                                    )}
                                                    <Form
                                                      onSubmit={handleSubmit(
                                                        onSubmit
                                                      )}
                                                    >
                                                      <Form.Group controlId="formBasicName">
                                                        <StyledFormLabel>
                                                          <b>{input1Title}</b>
                                                        </StyledFormLabel>
                                                        <Form.Control
                                                          name="name"
                                                          placeholder={input1Placeholder}
                                                          type="text"
                                                          ref={register({
                                                            required: true,
                                                          })}
                                                        />
                                                      </Form.Group>

                                                      <Form.Group controlId="formBasicEmail">
                                                        <StyledFormLabel>
                                                          <b>{input2Title}</b>
                                                        </StyledFormLabel>
                                                        <Form.Control
                                                          placeholder={input2Placeholder}
                                                          name="email"
                                                          type="email"
                                                          ref={register({
                                                            required: true,
                                                          })}
                                                        />
                                                      </Form.Group>

                                                      <Form.Group controlId="formBasicMessage">
                                                        <StyledFormLabel>
                                                          <b>{input3Title}</b>
                                                        </StyledFormLabel>
                                                        <Form.Control
                                                          as="textarea"
                                                          placeholder={input3Placeholder}
                                                          rows={3}
                                                          ref={register({
                                                            required: true,
                                                          })}
                                                          name="message"
                                                        />
                                                      </Form.Group>
                                                      <Button
                                                        block
                                                        size="lg"
                                                        variant="primary"
                                                        type="submit"
                                                        disabled={
                                                          !formState.isValid ||
                                                          isLoading
                                                        }
                                                      >
                                                        {submitButtonTitle}
                                                        {isLoading && (
                                                          <Spinner
                                                            animation="border"
                                                            as="span"
                                                            animation="border"
                                                            size="sm"
                                                          />
                                                        )}
                                                      </Button>
                                                    </Form>
                                                  </Col>
                                                </Row>
                                              </StyledContainer>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-6be929f"
                                        data-id="6be929f"
                                        data-element_type="column"
                                      >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                          <div
                                            className="elementor-element elementor-element-97fbc33 cms-animation-bottom-to-top2 elementor-widget elementor-widget-cms_image_single"
                                            data-id="97fbc33"
                                            data-element_type="widget"
                                            data-widget_type="cms_image_single.default"
                                          >
                                            <div className="elementor-widget-container animation-bottom">
                                              <div className="cms-image-single cms-animation-left-to-right ">
                                                <div className="cms-img-box">
                                                  <StaticImage
                                                    placeholder="none"
                                                    width="102"
                                                    height="86"
                                                    src="../images/image-home-1.png"
                                                    className="attachment-full size-full"
                                                    alt=""
                                                    loading="lazy"
                                                  />{" "}
                                                </div>
                                              </div>{" "}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    </div>
                  </article>
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default ContactUsPage;
